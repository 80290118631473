import React, { Component, useContext, useCallback } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { isDescendant } from "react-sortable-tree"
import classnames from "classnames"
import { TreeContext } from "./index"
import { Checkbox } from "@material-ui/core"
import { fontWeight } from "@material-ui/system"
import { Bold } from "react-feather"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

const NodeRenderer = (props) => {
  const {
    scaffoldBlockPxWidth,
    toggleChildrenVisibility,
    connectDragPreview,
    connectDragSource,
    isDragging,
    canDrop,
    canDrag,
    node,
    title,
    subtitle,
    draggedNode,
    path,
    treeIndex,
    isSearchMatch,
    isSearchFocus,
    buttons,
    className,
    style,
    didDrop,
    treeId,
    isOver, // Not needed, but preserved for other renderers
    parentNode, // Needed for dndManager
    rowDirection,
    ...otherProps
  } = props

  const {
    handleClickNode = () => null,
    classes,
    isViewMode,
    isMappingMode = false,
    handleCheckboxClick = () => null,
    canSelectOnlyLastLevel = false,
    categoryType = "",
    selectedNodeId = "",
  } = useContext(TreeContext)
  const { t } = useTranslation()
  const nodeTitle = title || node.title
  const nodeSubtitle = subtitle || node.subtitle
  const rowDirectionClass = rowDirection === "rtl" ? "rst__rtl" : null

  const history = useHistory()
  const enableColor = history?.location?.pathname.includes(
    t("/master-data/products/product-detailed-page")
  )

  const getCheckedStatus = useCallback(() => {
    let childrenCount = node?.children?.length
    if (!!!childrenCount) {
      return "unchecked"
    }
    let selectedChildrenCount = _.filter(
      node?.children || [],
      (o) => o?.isChecked
    ).length
    let unSelectedChildrenCount = _.filter(
      node?.children || [],
      (o) => !o?.isChecked
    ).length
    let status =
      selectedChildrenCount === childrenCount
        ? "checked"
        : unSelectedChildrenCount === childrenCount
        ? "unchecked"
        : "indeterminate"
    return status
  }, [node])

  let handle
  if (canDrag) {
    if (typeof node.children === "function" && node.expanded) {
      handle = (
        <div className="rst__loadingHandle">
          <div className="rst__loadingCircle">
            {[...new Array(12)].map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classnames(
                  "rst__loadingCirclePoint",
                  rowDirectionClass
                )}
              />
            ))}
          </div>
        </div>
      )
    } else {
      // Show the handle used to initiate a drag-and-drop
      handle = connectDragSource(<div className="rst__moveHandle" />, {
        dropEffect: "copy",
      })
    }
  }

  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node)
  const isLandingPadActive = !didDrop && isDragging

  let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth }
  if (rowDirection === "rtl") {
    buttonStyle = { right: -0.5 * scaffoldBlockPxWidth }
  }
  return (
    <div style={{ height: "100%" }} {...otherProps}>
      {toggleChildrenVisibility &&
        node.children &&
        (node.children.length > 0 || typeof node.children === "function") && (
          <div>
            <button
              //disabled={!isViewMode}
              type="button"
              aria-label={node.expanded ? "Collapse" : "Expand"}
              className={classnames(
                node.expanded ? "rst__collapseButton" : "rst__expandButton",
                rowDirectionClass
              )}
              style={buttonStyle}
              onClick={() =>
                toggleChildrenVisibility({
                  node,
                  path,
                  treeIndex,
                })
              }
            />

            {node.expanded && !isDragging && (
              <div
                style={{ width: scaffoldBlockPxWidth }}
                className={classnames("rst__lineChildren", rowDirectionClass)}
              />
            )}
          </div>
        )}

      <div className={classnames("rst__rowWrapper", rowDirectionClass)}>
        {/* Set the row preview to be used during drag and drop */}
        {connectDragPreview(
          <div
            className={classnames(
              "rst__row",
              isLandingPadActive && "rst__rowLandingPad",
              isLandingPadActive && !canDrop && "rst__rowCancelPad",
              isSearchMatch && "rst__rowSearchMatch",
              isSearchFocus && "rst__rowSearchFocus",
              rowDirectionClass,
              className,
              canDrag && "rst__borderLeft"
            )}
            style={{
              opacity: isDraggedDescendant ? 0.5 : 1,
              ...style,
            }}
          >
            <div
              className={classnames(
                "rst__rowContents",
                !canDrag && "rst__rowContentsDragDisabled",
                rowDirectionClass,
                {
                  selectedNode:
                    categoryType === "product"
                      ? node?._id === selectedNodeId
                      : isMappingMode && node?.isChecked,
                },
                {
                  unSelectableNode:
                    isMappingMode &&
                    canSelectOnlyLastLevel &&
                    !_.isEmpty(node?.children),
                }
              )}
              role="button"
              onClick={() =>
                isMappingMode &&
                canSelectOnlyLastLevel &&
                !_.isEmpty(node?.children)
                  ? null
                  : handleClickNode(node)
              }
              style={{
                color: isViewMode
                  ? "gray"
                  : enableColor
                  ? node?.children?.length > 0
                    ? "gray"
                    : "black"
                  : "",
                backgroundColor: isViewMode
                  ? ""
                  : enableColor
                  ? node?.children?.length > 0
                    ? ""
                    : "lightgrey"
                  : "",
              }}
            >
              {isViewMode && !isMappingMode && (
                <Checkbox
                  onClick={(e) => {
                    e.stopPropagation()
                    handleCheckboxClick(node, parentNode)
                  }}
                  indeterminate={
                    !node?.isChecked && getCheckedStatus() === "indeterminate"
                  }
                  checked={node?.isChecked || getCheckedStatus() === "checked"}
                  classes={{ root: classes.checkBoxRoot }}
                />
              )}
              <div
                className={classnames("rst__rowLabel", rowDirectionClass)}
                style={{ color: "gray", fontWeight: 400 }}
              >
                <span
                  className={classnames(
                    "rst__rowTitle",
                    node.subtitle && "rst__rowTitleWithSubtitle"
                  )}
                >
                  {typeof nodeTitle === "function"
                    ? nodeTitle({
                        node,
                        path,
                        treeIndex,
                      })
                    : nodeTitle}
                </span>

                {nodeSubtitle && (
                  <span className="rst__rowSubtitle">
                    {typeof nodeSubtitle === "function"
                      ? nodeSubtitle({
                          node,
                          path,
                          treeIndex,
                        })
                      : nodeSubtitle}
                  </span>
                )}
              </div>

              <div className="rst__rowToolbar">
                {buttons?.map((btn, index) => (
                  <div
                    key={index} // eslint-disable-line react/no-array-index-key
                    className="rst__toolbarButton"
                  >
                    {btn}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

NodeRenderer.defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  canDrag: false,
  toggleChildrenVisibility: null,
  buttons: [],
  className: "",
  style: {},
  parentNode: null,
  draggedNode: null,
  canDrop: false,
  title: null,
  subtitle: null,
  rowDirection: "ltr",
}

NodeRenderer.propTypes = {
  node: PropTypes.shape({}).isRequired,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  path: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,
  isSearchMatch: PropTypes.bool,
  isSearchFocus: PropTypes.bool,
  canDrag: PropTypes.bool,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  toggleChildrenVisibility: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  style: PropTypes.shape({}),

  // Drag and drop API functions
  // Drag source
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  parentNode: PropTypes.shape({}), // Needed for dndManager
  isDragging: PropTypes.bool.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  // Drop target
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,

  // rtl support
  rowDirection: PropTypes.string,
}

export default NodeRenderer
