import {
  Box,
  colors,
  Container,
  Dialog,
  Divider,
  makeStyles,
  Tab,
  Tabs,
  Card,
  CardHeader,
  CardContent,
  Button,
  Chip,
  Grid,
  Typography,
  Menu,
  FormControlLabel,
  FormControl,
  FormGroup,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  TableHead,
  TableContainer,
  Table,
} from "@material-ui/core"
import { green, red } from "@material-ui/core/colors"

import { default as MuiDialogContent } from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import { withStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import React, { useState } from "react"
import * as Features from "@features"
import { MoreVertical } from "react-feather"
import styled from "styled-components/macro"
import _ from "lodash"
import * as IconsGallery from "@iconsGallery"
import {
  Loop as LoopIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons"
import { useStyles } from "./styles"
import { useTranslation } from "react-i18next"

function TableListView(props) {
  const { title = "", rows = [], columns = [], style = {}, isLoading = false } =
    props || {}
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Card
      className={`${classes.cardStyles} makeStyles-cardStyles-support`}
      style={style}
    >
      <Grid container>
        <Grid
          item
          md={5}
          style={{ padding: "10px", paddingLeft: 16, paddingTop: 16 }}
        >
          <Typography
            className={`${classes.deviceTableTitle} makeStyles-deviceTableTitle-support`}
            variant="body"
          >
            {t(title)}
          </Typography>
        </Grid>
      </Grid>

      <CardContent
        style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}
        className="cls-deviceTable-Kit"
      >
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {!_.isEmpty(columns) &&
                  columns.map((col, index) => {
                    const { color = "#000" } = col

                    return (
                      <TableCell style={{ color }} key={index}>
                        {t(col.columnName)}
                      </TableCell>
                    )
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columns.map((cols, indexCols) => {
                    const { color = "#000" } = cols

                    return (
                      <TableCell
                        style={{ color: color }}
                        component="th"
                        scope="row"
                      >
                        {cols.isIconShow && row[cols.id] === "60% - 100%" ? (
                          <img alt="green"
                            className={classes.imageIcon}
                            src="/static/img/green.png"
                          />
                        ) : cols.isIconShow && row[cols.id] === "20% - 59%" ? (
                          <img alt="yellow"
                            className={classes.imageIcon}
                            src="/static/img/yellow.png"
                          />
                        ) : (
                          cols.isIconShow && (
                            <img alt="red"
                              className={classes.imageIcon}
                              src="/static/img/red.png"
                            />
                          )
                        )}
                        {isLoading ? (
                          <IconsGallery.DotLoading style={{ height: 20 }} />
                        ) : row[cols.id] || row[cols.id] === 0 ? (
                          typeof row[cols.id] === "number" ? (
                            row[cols.id]
                          ) : (
                            t(row[cols.id])
                          )
                        ) : (
                          t("N/A")
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

export default TableListView
export { TableListView }
