import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import OtpInput from "react-otp-input"
import { useHistory, Link, useLocation } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import _ from "lodash"
import { InfoOutlined, Visibility, VisibilityOff } from "@material-ui/icons"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import userApi from "../../services/user/api"
import * as Adm from "@adm"
import { CheckCircleIcon } from "@iconsGallery"
import * as ServiceAPI from "@services"
import { useTheme } from "styled-components"
import { useTranslation } from "react-i18next"
import { JSUtils } from "@utils"
const { headerAxios, API_BASE_URL } = ServiceAPI

const useStyles = makeStyles({
  dgrabImage: {
    marginTop: "80px",
    marginLeft: "80px",
    // backgroundColor: "white"
  },
  forgetNames: {
    fontWeight: "bold",
    marginTop: "20px",
  },
  emailName: {
    fontWeight: "200",
    color: "#D3D3D3",
  },
  textInput: {
    width: "80%",
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "0px",
    },
  },
  ButtonStyle: {
    color: "#FFFFFF",
    borderRadius: "8px",
    padding: "12px",
    fontWeight: "600",
    fontSize: "15px",
    width: "80%",
  },
  cancelButton: {
    backgroundColor: "#fff",
    border: "1px solid #3399FF",
    color: "#3399FF",
    borderRadius: "8px",
    padding: "12px",
    fontWeight: "600",
    fontSize: "15px",
    width: "80%",
  },
  LinkStyle: {
    color: "rgb(63 194 212)",
    textDecoration: "none",
  },
  paraStyle: {
    color: "#ADADAD",
    lineHeight: "5px",
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "Open Sans",
    fontSize: "16px",
  },
  paragraphVefication: {
    color: "rgb(114 118 123)",
  },
  verifiyEmail: {
    color: "#3399FF",
    fontSize: "20px",
  },
  imgStyle: {
    cursor: "pointer",
  },
})

const ForgetPassword = (props) => {
  const history = useHistory()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [alertMessage, setAlertMessage] = React.useState("")
  const [alertType, setAlertType] = React.useState("")
  const [alert, setAlert] = React.useState(false)
  const [update, setUpdate] = useState({})
  const [renderUI, setRenderUI] = useState(false)
  const theme = useTheme()
  const { t } = useTranslation()
  const isTablet = useMediaQuery(theme.breakpoints.down("md"))
  localStorage.removeItem("lang")
  const language_code = localStorage.getItem("language_code")

  useEffect(() => {
    history.push(window.location.pathname)
    const handlePopState = () => {
      window.location.href = window.location.origin
    }
    window.addEventListener("popstate", handlePopState)
    if (history.action === "POP") {
      window.location.href = window.location.origin
    }
    return () => {
      if (history.action === "POP") {
        window.location.href = window.location.origin
      }
      localStorage.removeItem("reloaded")
      handlePopState()
      window.removeEventListener("popstate", handlePopState)
    }
  }, [history])

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        window.location.href = window.location.origin
      }
      localStorage.removeItem("reloaded")
    }
  }, [])

  const validationSchema = {
    email: Yup.string()
      .email(t("Please enter valid email Id"))
      .required(t("Please enter your email id")),
  }

  useEffect(() => {
    setLoading(true)
    setRenderUI(false)
    if (localStorage.getItem("reloaded") === null) {
      setRenderUI(false)
      window.location.href =
        window.location.origin + `/auth/forgetpassword?lang=${language_code}`
      localStorage.setItem("reloaded", "true")
    }
    if (localStorage.getItem("reloaded") === "true") {
      setTimeout(() => {
        setRenderUI(true)
        setLoading(false)
        localStorage.removeItem("reloaded")
      }, 100)
    }
  }, [])

  return (
    <div>
      {renderUI && (
        <div
          className={`on direction_${JSON.parse(
            localStorage.getItem("language_display_direction")
          )}`}
        >
          <h1 className="cls-login-banner-text-page cls-login-banner-text-page1">
            {t("kc_cf_heading")}
          </h1>
          <h1 className="cls-login-banner-text-page cls-login-banner-text-page2">
            {t("kc_cf_subHeading")}
          </h1>
          <Formik
            enableReinitialize={true}
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={(values, { resetForm }) => {
              setAlert(false)
              let body = {
                email: values?.email,
                lang: language_code,
              }
              setLoading(true)
              userApi
                .getForgetPasswordLink(body)
                .then(async (res) => {
                  console.log(res?.data?.message, "responseForgetPassword")
                  await setAlertType(_.get(res, "data.status", "error"))
                  await setAlertMessage(
                    JSUtils.formatMessageCode(res?.data, t, "Try Again Later!")
                  )
                  await setAlert(true)
                  //await handleSignIn()
                  await setLoading(false)
                  setUpdate(res?.data?.data)
                })
                .catch((err) => {
                  console.log("error responseForgetPassword")
                  setAlertType("error")
                  setAlertMessage(
                    JSUtils.formatMessageCode(
                      err?.response?.data,
                      t,
                      "Try Again Later!"
                    )
                  )
                  setLoading(false)
                  setAlert(true)
                })
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm,
              handleReset,
            }) => {
              const handleClickShowPassword = (type, value) => {
                setFieldValue([type], value)
              }
              const handleMouseDownPassword = (event) => {
                event.preventDefault()
              }

              return (
                <form
                  noValidate
                  onSubmit={handleSubmit}
                  className={classes.cardContent}
                >
                  <Adm.BackdropOverlay open={loading} />
                  <Grid
                    container
                    direction="row"
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    xl={12}
                  >
                    <Grid
                      container
                      direction="row"
                      item
                      lg={7}
                      md={7}
                      sm={12}
                      xs={12}
                      xl={7}
                      style={{
                        margin: "-8px",
                        padding: "0px",
                        height: "950px",
                      }}
                    >
                      {isTablet ? (
                        <img
                          src="/static/img/avatars/login.jpg"
                          width="600"
                          height="648"
                        />
                      ) : (
                        <img
                          src="/static/img/avatars/login.jpg"
                          width="800"
                          height="648"
                          style={{ height: "100%", width: "100%" }}
                        />
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      lg={5}
                      md={5}
                      sm={12}
                      xs={12}
                      xl={5}
                      style={{
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Grid item className="cls-forgotpwd">
                        <Typography variant="h1" gutterBottom>
                          {t("Forgot_your_password_")}
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ padding: "10px" }}
                          gutterBottom
                          className={classes.paraStyle}
                        >
                          {t("ForgotPassword")}
                        </Typography>
                        <div style={{ marginTop: "20px" }}>
                          <TextField
                            value={values?.email}
                            className={`${classes.textInput} cls-textInput-support-kit`}
                            placeholder={t("Enter your email address")}
                            variant="outlined"
                            name="email"
                            autoComplete="off"
                            error={Boolean(touched?.email && errors?.email)}
                            helperText={touched?.email && errors?.email}
                            onBlur={handleBlur}
                            onChange={(props) => {
                              handleChange(props)
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton aria-label="email" edge="end">
                                    <MailOutlineIcon
                                      style={{
                                        width: "1.5em",
                                        height: "1.5em",
                                      }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={
                              !_.isEmpty(errors) || values?.email === ""
                            }
                            className={classes.ButtonStyle}
                            variant="contained"
                          >
                            {t("Send_password_reset_link")}
                          </Button>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <Button
                            onClick={() => {
                              //window.location.replace(window.location.origin)
                              history.push("/")
                            }}
                            className={classes.cancelButton}
                            variant="contained"
                          >
                            {t("CANCEL")}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                    {alert ? (
                      <Adm.SnackBar
                        open={true}
                        message={alertMessage}
                        type={alertType}
                      />
                    ) : null}
                  </Grid>
                </form>
              )
            }}
          </Formik>
        </div>
      )}
    </div>
  )
}

export default ForgetPassword
