import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import OtpInput from "react-otp-input"
import { useHistory, Link, useLocation } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import _ from "lodash"
import { InfoOutlined, Visibility, VisibilityOff } from "@material-ui/icons"
import userApi from "../../services/user/api"
import * as Adm from "@adm"
import { CheckCircleIcon } from "@iconsGallery"
import * as ServiceAPI from "@services"
import { useTheme } from "styled-components"
import { useTranslation } from "react-i18next"
import { JSUtils } from "@utils"
const { headerAxios, API_BASE_URL } = ServiceAPI

const useStyles = makeStyles({
  dgrabImage: {
    marginTop: "80px",
    marginLeft: "80px",
    // backgroundColor: "white"
  },
  forgetNames: {
    fontWeight: "bold",
    marginTop: "20px",
  },
  emailName: {
    fontWeight: "200",
    color: "#D3D3D3",
  },
  textInput1: {
    width: "100%",
  },
  ButtonStyle: {
    color: "#FFFFFF",
    borderRadius: "8px",
    padding: "12px",
    fontWeight: "600",
    fontSize: "15px",
    width: "80%",
  },
  cancelButton: {
    backgroundColor: "#fff",
    border: "1px solid #3399FF",
    color: "#3399FF",
    borderRadius: "8px",
    padding: "12px",
    fontWeight: "600",
    fontSize: "15px",
    width: "80%",
  },
  LinkStyle: {
    color: "rgb(63 194 212)",
    textDecoration: "none",
  },
  paraStyle: {
    color: "#ADADAD",
    lineHeight: "5px",
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "Open Sans",
    fontSize: "16px",
  },
  paragraphVefication: {
    color: "rgb(114 118 123)",
  },
  verifiyEmail: {
    color: "#3399FF",
    fontSize: "20px",
  },
  imgStyle: {
    cursor: "pointer",
  },
})

const ResetPassword = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const [langCode, setLangCode] = useState("en")
  const [loading, setLoading] = useState(false)
  const [alertMessage, setAlertMessage] = React.useState("")
  const [alertType, setAlertType] = React.useState("")
  const [alert, setAlert] = React.useState(false)
  const [update, setUpdate] = useState({})
  const [isVerified, setIsVerified] = useState(false)
  const [userId, setUserId] = useState("")
  const [authToken, setAuthToken] = useState("")
  const [getShowUI, setShowUI] = useState(false)
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down("md"))
  let regexPassword = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])/
  )
  localStorage.removeItem("lang")
  const language_code = localStorage.getItem("language_code")
  const translationData = localStorage.getItem("translationData")
  const [validToken, setValidToken] = useState("")
  const validationSchema = {
    newPassword: Yup.string()
      .required(t("Password is required"))
      .matches(JSUtils.getYupValidationCheck(regexPassword, true), {
        message: t("Password must be at least one uppercase, one lowercases"),
      })
      .min(8, t("Password must be at least 8 charaters.")),

    confirmPswd: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], t("Password must match"))
      .required(t("Password confirm is required")),
  }

  const handleRenderUI = () => {
    if (localStorage.getItem("reloaded") === null) {
      window.location.reload()
      localStorage.setItem("reloaded", "true")
    }
    if (localStorage.getItem("reloaded") === "true") {
      setShowUI(true)
      setLoading(false)
      setTimeout(() => {
        localStorage.removeItem("reloaded")
      }, 100)
    }
  }

  useEffect(() => {
    if (location.search && language_code && translationData) {
      let token = new URLSearchParams(location.search).get("token")
      setUserId(new URLSearchParams(location.search).get("code"))
      let langCode = localStorage.getItem("language_code")
      setLangCode(langCode)
      setAuthToken(token)
      setLoading(true)
      if (!token) {
        setValidToken("inValid")
        return
      }
      userApi
        .getAuthToken(token)
        .then((res) => {
          if (res?.data?.status === "success") {
            handleRenderUI()
            setValidToken("valid")
          } else {
            handleRenderUI()
            setValidToken("inValid")
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              handleRenderUI()
              setValidToken("inValid")
            } else {
              handleRenderUI()
              setValidToken("inValid")
            }
          } else if (error.request) {
            handleRenderUI()
            setValidToken("inValid")
          } else {
            handleRenderUI()
            setValidToken("inValid")
          }
        })
    }
  }, [location, language_code, translationData])
  
  return (
    <div style={{ display: getShowUI ? "block" : "none" }}>
      <div
        className={`on direction_${JSON.parse(
          localStorage.getItem("language_display_direction")
        )}`}
      >
        <h1 className="cls-login-banner-text-page cls-login-banner-text-page1">
          {t("kc_cf_heading")}
        </h1>
        <h1 className="cls-login-banner-text-page cls-login-banner-text-page2">
          {t("kc_cf_subHeading")}
        </h1>
        <Formik
          enableReinitialize={true}
          initialValues={{
            newPassword: "",
            confirmPswd: "",
            showNewPswdIcon: false,
            showConfirmPswdIcon: false,
          }}
          validationSchema={Yup.object().shape(validationSchema)}
          onSubmit={(values, { resetForm }) => {
            setAlert(false)
            let body = {
              code: userId,
              auth_token: authToken,
              password: values.newPassword,
            }
            setLoading(true)
            userApi
              .resetPassword(body)
              .then(async (res) => {
                console.log(res?.data?.message, "responseForgetPassword")
                await setAlertType("success")
                await setAlertMessage(
                  JSUtils.formatMessageCode(res?.data, t, "Try Again Later!")
                )
                await setAlert(true)
                setTimeout(() => {
                  window.location.replace(window.location.origin)
                }, 3000)
                await setLoading(false)
                setUpdate(res?.data?.data)
              })
              .catch((err) => {
                console.log("error responseForgetPassword")
                setAlertType("error")
                setAlertMessage(
                  JSUtils.formatMessageCode(
                    err?.response?.data,
                    t,
                    "Try Again Later!"
                  )
                )
                setLoading(false)
                setAlert(true)
              })
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            resetForm,
            handleReset,
          }) => {
            const handleClickShowPassword = (type, value) => {
              setFieldValue([type], value)
            }
            const handleMouseDownPassword = (event) => {
              event.preventDefault()
            }

            return (
              <form
                noValidate
                onSubmit={handleSubmit}
                className={classes.cardContent}
              >
                <Adm.BackdropOverlay open={loading} />
                <Grid
                  container
                  direction="row"
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  xl={12}
                >
                  <Grid
                    container
                    direction="row"
                    item
                    lg={7}
                    md={7}
                    sm={12}
                    xs={12}
                    xl={7}
                    style={{ margin: "-8px", padding: "0px", height: "950px" }}
                  >
                    {isTablet ? (
                      <img
                        src="/static/img/avatars/login.jpg"
                        width="600"
                        height="648"
                        alt=""
                      />
                    ) : (
                      <img
                        src="/static/img/avatars/login.jpg"
                        width="800"
                        height="648"
                        style={{ height: "100%", width: "100%" }}
                        alt=""
                      />
                    )}
                  </Grid>
                  <Grid
                    className="cls-resetpwd"
                    container
                    direction="row"
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    xl={5}
                    style={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {validToken === "valid" && (
                      <Grid
                        item
                        style={{ padding: 20, boxSizing: "border-box" }}
                      >
                        <Typography variant="h1" gutterBottom>
                          {t("Reset_your_password")}
                        </Typography>
                        <Typography
                          variant="caption"
                          gutterBottom
                          className={classes.paraStyle}
                        >
                          {t("ResetPassword")}
                        </Typography>
                        <div style={{ marginTop: "20px" }}>
                          <TextField
                            value={values?.newPassword}
                            variant="outlined"
                            className={`${classes.textInput} cls-textInput-support-kit`}
                            type={values.showNewPswdIcon ? "text" : "password"}
                            placeholder={t("Enter_new_password")}
                            name="newPassword"
                            error={Boolean(
                              touched?.newPassword && errors?.newPassword
                            )}
                            helperText={
                              touched?.newPassword && errors?.newPassword
                            }
                            onBlur={handleBlur}
                            onChange={(props) => {
                              handleChange(props)
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword(
                                        "showNewPswdIcon",
                                        !values.showNewPswdIcon
                                      )
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {values.showNewPswdIcon ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <TextField
                            value={values?.confirmPswd}
                            variant="outlined"
                            className={`${classes.textInput} cls-textInput-support-kit`}
                            type={
                              values.showConfirmPswdIcon ? "text" : "password"
                            }
                            placeholder={t("Enter_confirm_password")}
                            name="confirmPswd"
                            error={Boolean(
                              touched?.confirmPswd && errors?.confirmPswd
                            )}
                            helperText={
                              touched?.confirmPswd && errors?.confirmPswd
                            }
                            onBlur={handleBlur}
                            onChange={(props) => {
                              handleChange(props)
                            }}
                            onKeyUp={(e) => {
                              if (values?.newPassword === e?.target?.value) {
                                setIsVerified(true)
                              } else {
                                setIsVerified(false)
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword(
                                        "showConfirmPswdIcon",
                                        !values.showConfirmPswdIcon
                                      )
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {values.showConfirmPswdIcon ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={
                              !_.isEmpty(errors) ||
                              (values?.newPassword === "" &&
                                values?.confirmPswd === "")
                            }
                            className={classes.ButtonStyle}
                            variant="contained"
                          >
                            {t("Reset_Password")}
                          </Button>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <Button
                            onClick={() => {
                              window.location.replace(window.location.origin)
                            }}
                            className={classes.cancelButton}
                            variant="contained"
                          >
                            {t("CANCEL")}
                          </Button>
                        </div>
                      </Grid>
                    )}
                    {validToken === "inValid" && (
                      <Grid
                        item
                        style={{ padding: 20, boxSizing: "border-box" }}
                      >
                        <Typography variant="h1" gutterBottom>
                          {t("Reset_Password_link_has_been_expired_")}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          style={{
                            color: "blue",
                            textDecoration: "underlined",
                            cursor: "pointer",
                          }}
                          gutterBottom
                          onClick={() => {
                            history.push({
                              pathname: "/auth/forgetpassword",
                              search: `?lang=${langCode}`,
                            })
                          }}
                        >
                          {t("Please_click_here_to_regenerate")}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  {alert ? (
                    <Adm.SnackBar
                      open={true}
                      message={alertMessage}
                      type={alertType}
                    />
                  ) : null}
                </Grid>
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default ResetPassword
