import React, { useState, useEffect, useRef } from "react"
import _ from "lodash"
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Divider,
  TextField,
  Grid,
  Button,
  Typography,
} from "@material-ui/core"
import * as Adm from "@adm"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import update from "immutability-helper"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import DraggableTreeView from "../../../components/DraggableTreeView"
import {
  getProcessedFlatData,
  getNewFlatDataWithOldExpandedStatus,
  getTreeData,
  getFlatData,
  getAllChildrenIds,
  getNewFlatDataWithOldExpandedStatusArray,
} from "../../../components/DraggableTreeView/treeDataUtils"
import { fetchAllHierarchy } from "../../../services/masterData/locations/actions"
import { fetchAllCategories } from "../../../services/masterData/products/actions"
import locationsAPI from "../../../services/masterData/locations/api"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { JSUtils } from "@utils"

const getTreeDataFxn = (x, idPath, parentIdPath) =>
  getTreeData(
    x,
    (n) => _.get(n, idPath, _.get(n, `node.${idPath}`, undefined)),
    (n) => _.get(n, parentIdPath, ""),
    ""
  )
const initSnackbarProps = {
  isOpen: false,
  message: "",
  type: "info",
  autoHideDuration: 2500,
}

const PageHeaderBreadcrumbKit = styled(Grid)`
  button {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 18px;
    padding-right: 18px;
    max-width: unset;
  }
  margin-bottom: 24px;
  border-bottom: 1px #e0e0e0 solid;
  padding-bottom: 15px;
`
const useStyles = makeStyles((theme) => ({
  locationGrid: {
    width: "100%",
    height: "auto",
    border: "0px solid red",
    background: "#ffff",
    overflowX: "auto",
  },
  textFieldInput: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "var(--primaryColor)",
    },
  },
}))
const Hierarchy = ({
  isWriteAllowed,
  isViewMode = false,
  canSelectOnlyLastLevel = false,
  isMappingMode = false,
  idPath = "hierarchyId",
  titlePath = "name",
  parentIdPath = "parent",
  updateSelectedIds = () => {},
  lookupType = "location",
  ProductHierarchyDetail = false,
  selectedItem = [],
  expandedNodeId = "",
  parentArrayId = [],
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let history = useHistory()
  const classes = useStyles()
  const { categories } = useSelector((state) =>
    lookupType === "product"
      ? _.get(state, "productsReducer", {})
      : _.get(state, "locationHierarchyReducer", {})
  )
  const [flatTreeData, setFlatTreeData] = useState(() => categories?.list || [])
  const [treeData, setTreeData] = useState(() =>
    getTreeDataFxn(categories?.list || [], idPath, parentIdPath)
  )
  const [processedFlatData, setProcessedFlatData] = useState([])
  const [selectedCategories, setSelectedCategories] = useState(selectedItem)
  const [snackbarProps, setSnackbarProps] = useState(initSnackbarProps)
  const [addNodeFormProps, setAddNodeFormProps] = useState({
    open: false,
    parent: {},
    parentLabelPath: "name",
  })
  const nodeToBeKeptInExpandedStatus = useRef(expandedNodeId)
  const [loading, setLoading] = useState(false)
  let isFormSubmitting = useRef(false)

  const fetchNewFlatTreeData = (
    oldFlatData = getFlatData(treeData, (n) => _.get(n, idPath, ""))
  ) => {
    if (lookupType === "product") {
      dispatch(fetchAllCategories())
    } else {
      dispatch(fetchAllHierarchy())
    }
  }
  const BreadcrumbList = [
    {
      name: "Master Data",
      disable: true,
    },
    {
      name: "Locations",
      url: t("/master-data/locations"),
    },
    {
      name: "Hierarchy Editor",
    },
  ]
  useEffect(() => {
    updateSelectedIds([...selectedCategories])
  }, [selectedCategories])

  useEffect(() => {
    setProcessedFlatData(
      getProcessedFlatData({
        data: flatTreeData,
        checkedList: selectedCategories,
        idPath,
      })
    )
  }, [flatTreeData])

  useEffect(() => {
    let temp = getTreeDataFxn(processedFlatData, idPath, parentIdPath)
    setTreeData(temp)
  }, [processedFlatData])

  useEffect(() => {
    if (ProductHierarchyDetail) {
      setFlatTreeData(
        getNewFlatDataWithOldExpandedStatusArray(
          categories?.list || [],
          getFlatData(treeData),
          idPath,
          nodeToBeKeptInExpandedStatus.current,
          parentArrayId,
          () => {
            nodeToBeKeptInExpandedStatus.current = undefined
          }
        )
      )
    } else {
      setFlatTreeData(
        getNewFlatDataWithOldExpandedStatus(
          categories?.list || [],
          getFlatData(treeData),
          idPath,
          nodeToBeKeptInExpandedStatus.current,
          () => {
            nodeToBeKeptInExpandedStatus.current = undefined
          }
        )
      )
    }
  }, [categories.list])

  useEffect(fetchNewFlatTreeData, [])

  const handleNodeMove = (newParentData, nodeData) => {
    let tempData = nodeData
    let tempParentID = newParentData?.[idPath] || ""
    tempData[parentIdPath] = tempParentID
    locationsAPI
      .updateHierarchy(nodeData?._id, tempData)
      .then((resp) => {
        fetchNewFlatTreeData(getFlatData(treeData))
        const messageText = JSUtils.formatMessageCode(resp?.data, t)
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: messageText,
          type: "success",
        })
      })
      .catch((err) => {
        const errorText = JSUtils.formatMessageCode(
          err?.response?.data,
          t,
          "Sorry! Update operation failed"
        )
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: errorText,
          type: "error",
        })
      })
  }

  const handleAddNode = (nodeProps) => {
    let idx = _.findIndex(processedFlatData, [idPath, _.get(nodeProps, idPath)])
    let temp = update(processedFlatData[idx], { $merge: { expanded: true } })
    nodeToBeKeptInExpandedStatus.current = _.get(nodeProps, "hierarchyId")
    setAddNodeFormProps((c) => ({
      ...c,
      open: true,
      parent: temp,
      isEditMode: false,
      defaultValue: "",
      cancelCallback: () => {
        nodeToBeKeptInExpandedStatus.current = undefined
      },
    }))
  }

  const handleAddNodeFormSave = (name, parent) => {
    if (isFormSubmitting.current === true) {
      return null
    }
    isFormSubmitting.current = true
    setLoading(true)
    let bodyData = {
      name,
      [parentIdPath]: _.get(parent, idPath, ""),
    }
    locationsAPI
      .createHierarchy(bodyData)
      .then((resp) => {
        fetchNewFlatTreeData(getFlatData(treeData))
        const messageText = JSUtils.formatMessageCode(resp?.data, t)
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: messageText,
          type: "success",
        })
        isFormSubmitting.current = false
        setLoading(false)
        handleAddNodeFormCancel()
      })
      .catch((err) => {
        const errorText = JSUtils.formatMessageCode(
          err?.response?.data,
          t,
          "Sorry! Couldn't create Node"
        )
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: errorText,
          type: "error",
        })
        isFormSubmitting.current = false
        setLoading(false)
      })
  }

  const handleNodeEditFormSave = (name, ogData) => {
    let bodyData = { ...ogData }
    bodyData.name = name
    locationsAPI
      .updateHierarchy(_.get(ogData, "_id"), bodyData)
      .then((resp) => {
        fetchNewFlatTreeData(getFlatData(treeData))
        const messageText = JSUtils.formatMessageCode(resp?.data, t)
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: messageText,
          type: "success",
        })
        handleAddNodeFormCancel()
      })
      .catch((err) => {
        const errorText = JSUtils.formatMessageCode(
          err?.response?.data,
          t,
          "Sorry! Couldn't edit Node"
        )
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: errorText,
          type: "error",
        })
      })
  }

  const handleNodeDelete = (nodeProps) => {
    locationsAPI
      .deleteHierarchy(_.get(nodeProps, "_id", ""))
      .then((resp) => {
        fetchNewFlatTreeData(getFlatData(treeData))
        const messageText = JSUtils.formatMessageCode(resp?.data, t)
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: messageText,
          type: "success",
        })
      })
      .catch((err) => {
        const errorText = JSUtils.formatMessageCode(
          err?.response?.data,
          t,
          "Sorry! Couldn't delete Node"
        )
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: errorText,
          type: "error",
        })
      })
  }

  const handleAddNodeFormCancel = () =>
    setAddNodeFormProps((c) => ({ ...c, open: false, parent: {} }))

  const handleNodeClick = (node) => {
    let temp = []
    if (
      _.findIndex(selectedCategories, (x) => x === _.get(node, idPath)) ===
        -1 &&
      _.findIndex(selectedCategories, (x) => x === _.get(node, titlePath)) ===
        -1
    ) {
      temp = [_.get(node, idPath), _.get(node, titlePath)]
    }
    setSelectedCategories(temp)
    setProcessedFlatData(
      getProcessedFlatData({
        data: getFlatData(treeData),
        checkedList: temp,
        idPath,
      })
    )
  }

  const handleCheckboxClick = (node, parentNode) => {
    console.log(node, parentNode)
    let exstIdx = _.findIndex(selectedCategories, (o) => o === node?.[idPath])
    let childrenIds = getAllChildrenIds(node, idPath) || []
    let allIdsOfNode = [node?.[idPath], ...childrenIds]
    let temp
    if (exstIdx === -1) {
      temp = update(selectedCategories, { $push: allIdsOfNode })
    } else {
      temp = _.without(selectedCategories, ...(allIdsOfNode || []))
    }
    setSelectedCategories(temp)
    setProcessedFlatData(
      getProcessedFlatData({
        data: getFlatData(treeData),
        checkedList: temp,
        idPath,
      })
    )
  }

  const handleonEditClick = (node) => {
    setAddNodeFormProps((c) => ({
      ...c,
      open: true,
      parent: node.parentNode,
      isEditMode: true,
      defaultValue: _.get(node, "node.name", ""),
      handleNodeEditFormSave: (v) =>
        handleNodeEditFormSave(v, _.get(node, "node", {})),
    }))
  }

  return (
    <>
      <Helmet title={t("Hierarchy Editor")} />
      <Adm.BackdropOverlay open={loading} />
      {history?.location?.pathname === t("/master-data/locations/hierarchy") ? (
        <div style={{ borderBottom: "1px solid #E0E0E0", width: "100%" }}>
          <PageHeaderBreadcrumbKit
            container
            justify="space-between"
            alignContent="center"
            alignItems="center"
            className="PageHeaderBreadcrumbKit"
          >
            <Grid>
              <Adm.Breadcrumb list={BreadcrumbList} />
            </Grid>
          </PageHeaderBreadcrumbKit>
          <Typography
            className="cls-hierarchyeditorheader"
            style={{
              fontWeight: 600,
              background: "#ffff",
              fontSize: "20px",
              padding: "10px 10px",
            }}
          >
            {t("Hierarchy Editor")}
          </Typography>
        </div>
      ) : null}
      <Grid
        container
        direction="row"
        xs={12}
        lg={12}
        xl={12}
        sm={12}
        md={12}
        className={`${classes.locationGrid} cls-hierarchyeditor`}
      >
        <DraggableTreeView
          ProductHierarchyDetail={ProductHierarchyDetail}
          treeData={treeData}
          onChangeInTreeData={setTreeData}
          titlePath={titlePath}
          setLoading={setLoading}
          isViewMode={isViewMode}
          handleNodeMove={handleNodeMove}
          handleAddNode={handleAddNode}
          handleCheckboxClick={handleCheckboxClick}
          handleClickNode={handleNodeClick}
          handleNodeDelete={handleNodeDelete}
          onAddClick={() =>
            setAddNodeFormProps((c) => ({
              ...c,
              open: true,
              parent: {},
              isEditMode: false,
              defaultValue: "",
            }))
          }
          onEditClick={handleonEditClick}
          handlerParamsMaker={(x) => _.get(x, "node", "")}
          noNodesText="No Hierarchy Found"
          addNodeText="Add Hierarchy"
          lookupType={lookupType === "product" ? "Product" : "Location"}
          showAddRootNodeButton={!isViewMode}
          canSelectOnlyLastLevel={canSelectOnlyLastLevel}
          isMappingMode={isMappingMode}
          isWriteAllowed={isWriteAllowed}
          parentArrayId={parentArrayId}
        />
      </Grid>
      {snackbarProps?.isOpen && (
        <Adm.SnackBar
          open
          message={snackbarProps?.message || "Uh! May be wait!?"}
          type={snackbarProps?.type || "info"}
          icon={snackbarProps?.icon || undefined}
          handleClose={() => setSnackbarProps(initSnackbarProps)}
        />
      )}
      <AddNodeForm
        {...addNodeFormProps}
        isFormSubmitting={isFormSubmitting}
        handleSave={handleAddNodeFormSave}
        handleCancel={handleAddNodeFormCancel}
        t={t}
      />
    </>
  )
}

const AddNodeForm = ({
  open = false,
  defaultValue = "",
  isFormSubmitting = { isFormSubmitting },
  parent,
  handleNodeEditFormSave = () => {},
  isEditMode = false,
  parentLabelPath = "",
  handleCancel = () => {},
  handleSave = () => {},
  t,
}) => {
  const [name, setName] = useState(defaultValue)
  const [errorMsg, setErrorMsg] = useState("")
  const classes = useStyles()

  useEffect(() => {
    setName(defaultValue)
    return () => setName("")
  }, [open, defaultValue])

  const validate = (data) => {
    let error = ""
    if (data.length === 0) {
      error = "Enter Hierarchy Name"
    } else if (data.length < 3) {
      error = "Hierarchy Name can be of min-3 max-255 characters"
    } else if (data.length > 255) {
      error = "Hierarchy Name can be of min-3 max-255 characters"
    } else {
      error = ""
    }
    setErrorMsg(error)
    return error
  }

  const handleCancelFunc = () => {
    setErrorMsg("")
    handleCancel()
  }

  return (
    <Dialog open={open} fullWidth maxWidth="xs" className="cls-hierarchynode">
      <DialogTitle>
        {isEditMode ? t("Edit") : t("Add")} {t("Node")}
      </DialogTitle>
      <Divider />
      <div style={{ padding: 16 }}>
        <Grid container direction="column" style={{ gap: 8 }}>
          {isEditMode && parent?.name !== undefined && (
            <TextField
              size="small"
              margin="dense"
              style={{ backgroundColor: "#DCDCDC" }}
              variant="outlined"
              label={t("Parent*")}
              className={`${classes.textFieldInput} makeStyles-textFieldInput-support`}
              disabled
              value={parent?.name}
            />
          )}
          {!isEditMode && parent?.name !== undefined && (
            <TextField
              size="small"
              margin="dense"
              style={{ backgroundColor: "#DCDCDC" }}
              variant="outlined"
              label={t("Parent*")}
              className={`${classes.textFieldInput} makeStyles-textFieldInput-support`}
              disabled
              value={parent?.name}
            />
          )}
          <TextField
            size="small"
            margin="dense"
            variant="outlined"
            label={t("Name")}
            value={name}
            error={errorMsg}
            className={`${classes.textFieldInput} makeStyles-textFieldInput-support`}
            helperText={errorMsg !== "" ? t(errorMsg) : ""}
            onBlur={(e) => validate(e.target.value)}
            onChange={(e) => {
              validate(e.target.value)
              setName(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.which === 32 && e.target.value === "") {
                e.preventDefault()
              }
            }}
          />
        </Grid>
      </div>
      <Divider />
      <DialogActions style={{ paddingRight: 16 }}>
        <Button
          size="medium"
          disableElevation
          variant="outlined"
          color="primary"
          style={{
            color: "var(--primaryColor)",
            borderColor: "var(--primaryColor)",
          }}
          onClick={handleCancelFunc}
        >
          {t("Cancel")}
        </Button>
        <Button
          size="medium"
          disableElevation
          variant="contained"
          color="primary"
          style={{ backgroundColor: "var(--primaryColor)" }}
          onClick={
            errorMsg === "" &&
            (isEditMode
              ? () => {
                  validate(name) === "" && handleNodeEditFormSave(name)
                }
              : () => {
                  validate(name) === "" && handleSave(name, parent)
                })
          }
          disabled={isFormSubmitting.current}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Hierarchy
