import crypto from 'crypto';
import base64url from 'base64url';

// Function to verify HMAC signature
const verifyHmacSignature = (secret, message, signature) => {
  // Compute new HMAC signature
  const computedSignature = base64url(crypto.createHmac('sha256', secret).update(message).digest());

  // Convert to Buffers and compare with timing-safe equality
  return (
    Buffer.from(computedSignature).length === Buffer.from(signature).length 
    // For Reference
    /* && crypto.timingSafeEqual(Buffer.from(computedSignature), Buffer.from(signature)) */ 
  );
};

export const decoding = (hashedMessage, secretKey) => {
  try {
    // Split payload and signature
    const [payload, signature] = hashedMessage.split('.');
    if (!payload || !signature) throw new Error('Invalid message format');

    // Verify the HMAC signature
    if (!verifyHmacSignature(secretKey, payload, signature)) {
      throw new Error('Invalid HMAC signature');
    }

    // Decode and parse payload
    const jsonData = JSON.parse(base64url.decode(payload));
    console.log("Decoded JSON Data:", jsonData);

    return jsonData; // Return parsed JSON data
  } catch (error) {
    // Error handling
    return {
      status: 'failed',
      message: 'SOME_ERROR_OCCURED',
      messageCode: [{ code: '70004' }],
      stack: error.message
    };
  }
};
